import { useEffect, useState } from 'react';
export function useScrollSpy(sectionIds: string[], options?: IntersectionObserverInit): string | null {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  useEffect(() => {
    const observers: HTMLElement[] = [];
    const observerCallback = (entries: IntersectionObserverEntry[]): void => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
      ...options
    });
    sectionIds.forEach(id => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
        observers.push(element);
      }
    });
    return () => {
      observers.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, [sectionIds, options]);
  return activeSection;
}