'use client';

import { FairLogoText } from '@/components/fair-logo';
import { Link } from '@/components/Link';
import { motion } from 'framer-motion';
import { BadgeCent, Coins, HandCoins } from 'lucide-react';
import { Navbar } from './_components/navbar';
import { BackgroundGradient } from './_components/shader';
export default function Landing() {
  return <div className="h-screen overflow-auto overscroll-auto scroll-smooth bg-gray-950" data-sentry-component="Landing" data-sentry-source-file="page.tsx">
      <Navbar data-sentry-element="Navbar" data-sentry-source-file="page.tsx" />

      <main className="relative min-h-screen w-full overflow-hidden">
        {/* Hero Section */}
        <motion.div initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} transition={{
        duration: 3,
        delay: 1.5
      }} className="absolute inset-0" data-sentry-element="unknown" data-sentry-source-file="page.tsx">
          <BackgroundGradient data-sentry-element="BackgroundGradient" data-sentry-source-file="page.tsx" />
        </motion.div>
        <section id="landing" className="relative flex h-screen min-h-screen w-full items-center px-6">
          <div className="relative mx-auto flex w-full max-w-3xl flex-col items-center rounded-3xl border-white/10">
            <h1 className="text-center text-5xl font-semibold text-white sm:text-7xl">
              {'Welcome to '.split('').map((ch, i) => <motion.span key={i} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} transition={{
              delay: i * 0.05
            }}>
                  {ch == ' ' ? <span>&nbsp;</span> : ch}
                </motion.span>)}
              <motion.div initial={{
              y: 4,
              scale: 0.5,
              opacity: 0
            }} animate={{
              y: 0,
              scale: 1,
              opacity: 1
            }} transition={{
              delay: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
                <FairLogoText className="" data-sentry-element="FairLogoText" data-sentry-source-file="page.tsx" />
              </motion.div>
            </h1>
            <motion.p className="mt-6 text-center text-lg font-medium text-gray-300 sm:text-2xl" initial={{
            y: 24,
            opacity: 0
          }} animate={{
            y: 0,
            opacity: 1
          }} transition={{
            delay: 1
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              Chat with the best AI models, with no subscriptions
            </motion.p>
            <motion.div className="mt-24" initial={{
            y: 24,
            scale: 0.95,
            opacity: 0
          }} animate={{
            y: 0,
            scale: 1,
            opacity: 1
          }} transition={{
            delay: 1.3,
            type: 'spring'
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <Link href="/chat" className="mx-auto rounded-full bg-fuchsia-500 px-6 py-4 text-xl font-bold text-white" data-sentry-element="Link" data-sentry-source-file="page.tsx">
                Get started
              </Link>
            </motion.div>
          </div>
        </section>
        <section id="features" className="relative px-4 pt-32">
          <h2 className="text-center text-5xl font-bold text-white">
            Features
          </h2>
          <div className="mx-auto mt-16 grid max-w-5xl grid-cols-1 gap-6 pb-16 sm:grid-cols-7">
            <motion.div className="rounded-2xl border border-white/10 bg-black/10 p-6 text-white backdrop-blur-sm sm:col-span-4" animate={{
            scale: 1
          }} whileHover={{
            scale: 1.05
          }} transition={{
            type: 'spring',
            bounce: 0.7
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <p className="mb-2 text-2xl font-bold sm:text-4xl">
                Pay-as-you-go
              </p>
              <p className="sm:text-lg">
                Chat indefinitely with the free models and then pay only for the
                messages you send with the advanced models. Say goodbye to
                subscriptions.
              </p>
            </motion.div>
            <motion.div className="rounded-2xl border border-white/10 bg-black/10 p-6 text-white backdrop-blur-sm sm:col-span-3" whileHover={{
            scale: 1.05
          }} transition={{
            type: 'spring',
            bounce: 0.7
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <p className="mb-2 text-2xl font-bold sm:text-4xl">
                Ridiculously cheap
              </p>
              <p className="sm:text-lg">
                Average user saves 95% compared to ChatGPT Plus subscription. Go
                from $20 to less than $1 a month.
              </p>
            </motion.div>
            <motion.div className="rounded-2xl border border-white/10 bg-black/10 p-6 text-white backdrop-blur-sm sm:col-span-3" whileHover={{
            scale: 1.05
          }} transition={{
            type: 'spring',
            bounce: 0.7
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <p className="mb-2 text-2xl font-bold sm:text-4xl">
                All AI models in one place
              </p>
              <p className="sm:text-lg">
                No more choosing which subscription to buy. Switch between
                models mid-conversation.
              </p>
            </motion.div>
            <motion.div className="rounded-2xl border border-white/10 bg-black/10 p-6 text-white backdrop-blur-sm sm:col-span-4" whileHover={{
            scale: 1.05
          }} transition={{
            type: 'spring',
            bounce: 0.7
          }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
              <p className="mb-2 text-2xl font-bold sm:text-4xl">
                No rate limits
              </p>
              <p className="sm:text-lg">
                Subscription-based chatbots have to use rate limits to prevent
                overuse. We charge you per message, so you can chat as much as
                you want!
              </p>
            </motion.div>
          </div>
        </section>

        <section id="pricing" className="relative px-4 py-32">
          <h2 className="mb-8 text-center text-5xl font-bold text-white">
            Pricing
          </h2>
          <div className="mx-auto max-w-2xl rounded-3xl border border-white/10 bg-black/10 p-6 text-white backdrop-blur-sm">
            <p className="mb-2 text-center">Starts and ends at</p>
            <p className="mb-8 text-center text-5xl font-bold">0$ / month</p>

            <p className="mb-1 flex items-center gap-2 text-2xl font-bold">
              <Coins className="size-5" data-sentry-element="Coins" data-sentry-source-file="page.tsx" />
              Credit based system
            </p>
            <ul className="mb-4 list-inside list-disc text-gray-200">
              <li>You can chat with the free models indefinitely.</li>
              <li>
                You add credits to your account to chat with premium models.
              </li>
              <li>You only pay for the answers generated for you.</li>
              <li>The exact price varies by model and length of message.</li>
              <li>
                An average message from the most advanced model costs less than
                a cent.
              </li>
              <li>
                Average user spends less than a $1 per month on credits, while
                using the most advanced AI out there.
              </li>
            </ul>
            <div className="flex flex-col gap-6 rounded-xl border border-fuchsia-500/50 bg-fuchsia-500/20 p-4">
              <div className="flex items-center gap-4">
                <BadgeCent className="size-8 shrink-0 text-fuchsia-500" data-sentry-element="BadgeCent" data-sentry-source-file="page.tsx" />
                <div>
                  <p className="text-xl font-bold">
                    Get $0.50 FREE credit when you sign up!
                  </p>
                  <p className="text-gray-200">
                    That&apos;s enough for over a month of regular usage.
                  </p>
                </div>
              </div>
              <motion.div className="mx-auto" initial={{
              scale: 0.95
            }} animate={{
              scale: 1
            }} transition={{
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 1,
              ease: 'easeInOut'
            }} data-sentry-element="unknown" data-sentry-source-file="page.tsx">
                <Link href="/chat" className="flex size-full items-center gap-2 rounded-full bg-fuchsia-500 px-4 py-2 text-lg font-bold text-white" data-sentry-element="Link" data-sentry-source-file="page.tsx">
                  Claim
                  <HandCoins className="size-5 shrink-0" data-sentry-element="HandCoins" data-sentry-source-file="page.tsx" />
                </Link>
              </motion.div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-black py-8">
        <div className="container mx-auto px-4 text-center text-sm text-gray-400">
          <p>&copy; {new Date().getFullYear()} fAIr. All rights reserved.</p>
        </div>
      </footer>
    </div>;
}