'use client';

import { FairLogoText } from '@/components/fair-logo';
import { Link } from '@/components/Link';
import { useScrollSpy } from '@/lib/hooks/use-scroll-spy';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
function NavbarItem({
  id,
  selected,
  children
}: PropsWithChildren<{
  id: string;
  selected: boolean;
}>) {
  return <Link href={`#${id}`} className={cn('relative flex items-center rounded-full px-2 font-medium text-white hover:bg-white/10 sm:px-3')} data-sentry-element="Link" data-sentry-component="NavbarItem" data-sentry-source-file="navbar.tsx">
      {selected && <motion.div className="absolute inset-0 rounded-full bg-fuchsia-500/40" layoutId="section-indicator" />}
      <span className="relative">{children}</span>
    </Link>;
}
export function Navbar() {
  const activeSection = useScrollSpy(['landing', 'features', 'pricing'], {
    threshold: 0.5
  });
  return <header data-sentry-component="Navbar" data-sentry-source-file="navbar.tsx">
      <motion.nav className="fixed inset-x-4 top-4 z-20 mx-auto flex max-w-2xl gap-0 rounded-full border border-white/20 bg-white/20 p-2 text-sm shadow-lg blur backdrop-blur-lg sm:gap-2 sm:text-base" initial={{
      opacity: 0,
      filter: 'blur(64px)'
    }} animate={{
      opacity: 1,
      filter: 'blur(0px)'
    }} transition={{
      duration: 1,
      delay: 1.5
    }} data-sentry-element="unknown" data-sentry-source-file="navbar.tsx">
        <Link href="/" className="me-auto flex items-center gap-2 rounded-full bg-white px-3 py-1 text-xl shadow-lg" data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
          {/* <FairLogo className="text-2xl" /> */}
          <FairLogoText data-sentry-element="FairLogoText" data-sentry-source-file="navbar.tsx" />
        </Link>
        <NavbarItem id="landing" selected={activeSection === 'landing'} data-sentry-element="NavbarItem" data-sentry-source-file="navbar.tsx">
          About
        </NavbarItem>
        <NavbarItem id="features" selected={activeSection === 'features'} data-sentry-element="NavbarItem" data-sentry-source-file="navbar.tsx">
          Features
        </NavbarItem>
        <NavbarItem id="pricing" selected={activeSection === 'pricing'} data-sentry-element="NavbarItem" data-sentry-source-file="navbar.tsx">
          Pricing
        </NavbarItem>
        <Link href="/chat" className="ms-auto flex items-center rounded-full bg-fuchsia-500 px-4 py-1 font-bold text-white shadow-lg" data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
          Try it
        </Link>
      </motion.nav>
    </header>;
}