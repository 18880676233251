import { cn } from '@/lib/utils';
export function FairLogo({
  className
}: {
  className?: string;
}) {
  return <div className={cn('relative size-[1em] rounded-[0.2em] bg-gradient-to-br from-fuchsia-500 to-indigo-600', className)} data-sentry-component="FairLogo" data-sentry-source-file="fair-logo.tsx">
      <div className="absolute inset-0 m-auto h-1/5 w-1/2 rounded-[0.75em] rounded-br-[0.25em] bg-gradient-to-t from-gray-100 to-white shadow-lg"></div>
    </div>;
}
export function FairLogoText({
  className
}: {
  className?: string;
}) {
  return <span className={cn('bg-gradient-to-br from-fuchsia-500 to-indigo-600 bg-clip-text font-bold text-transparent dark:to-indigo-500', className)} data-sentry-component="FairLogoText" data-sentry-source-file="fair-logo.tsx">
      fAIr
    </span>;
}